import React from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from 'components/Section'
import { InfoBlock } from 'components/InfoBlock'
import { SECTION } from 'utils/section'
import { ConceptLarge } from './partials/ConceptLarge'
import { ConceptSmall } from './partials/ConceptSmall'
import styles from './Concept.module.scss'

export const Concept = () => {
  const { t } = useTranslation()
  return (
    <Section id={SECTION.WELCOME}>
      <InfoBlock
        title={t('concept.title')}
        content={
          <>
            <div className={styles['concept-small']}>
              <ConceptSmall />
            </div>
            <div className={styles['concept-large']}>
              <ConceptLarge />
            </div>
          </>
        }
      />
    </Section>
  )
}
