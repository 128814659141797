import React from 'react'
import style from './Button.module.scss'

export const Button = ({ type = 'button', children, ...rest }) => (
  <button
    className={style['button']}
    type={type}
    {...rest}
  >
    {children}
  </button>
)
