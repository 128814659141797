import React from 'react'
import { useTranslation } from 'react-i18next'
import { SECTION } from 'utils/section'
import { Section } from 'components/Section'
import { InfoBlock } from 'components/InfoBlock'
import image from './img/lauri_avartek.jpeg'
import styles from './Company.module.scss'

export const Company = () => {
  const { t } = useTranslation()
  return (
    <Section id={SECTION.COMPANY} borderTop grey>
      <InfoBlock
        title={t('company.title')}
        content={
          <>
            <p>{t('company.description')}</p>
            <img className={styles['image']} src={image} alt="Lauri Avartek" />
          </>
        }
      />
    </Section>
  )
}
