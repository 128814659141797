import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SECTION } from 'utils/section'
import { scrollTo } from 'utils/scrollTo'
import styles from './Navigation.module.scss'

export const Navigation = () => {
  const { t } = useTranslation()
  return (
    <div className={styles['container']}>
      <NavLink to="/" onClick={() => scrollTo(SECTION.WELCOME)}>{t('hero.navigation.whatWeDo')}</NavLink>
      <NavLink to="/" onClick={() => scrollTo(SECTION.COMPANY)}>{t('hero.navigation.company')}</NavLink>
      <NavLink to="/" onClick={() => scrollTo(SECTION.CONTACT)}>{t('hero.navigation.contacts')}</NavLink>
    </div>
  )
}
