import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { InfoText } from '../../common/InfoText'
import img_1 from '../../common/img/img_1.svg'
import img_2 from '../../common/img/img_2.jpg'
import img_3 from '../../common/img/img_3.svg'
import img_4 from '../../common/img/img_4.svg'
import styles from './ConceptSmall.module.scss'

export const ConceptSmall = () => {
  const { t } = useTranslation()
  return (
    <div className={styles['container']}>
      <div className={styles['row']}>
        <div className={styles['column-1']}>
          <div className={classnames(styles['line'], styles['line-1'])} />
          <div className={classnames(styles['line'], styles['line-2'])} />
          <img className={styles['image-1']} src={img_1} alt="High-density laser scanning" />
        </div>
        <div className={styles['column-2']}>
          <div className={classnames(styles['line'], styles['line-3'])} />
          <InfoText number={1} text={t('concept.one')} />
          <img className={styles['image-1-sm']} src={img_1} alt="High-density laser scanning" />
          <div className={styles['screenshot']}>
            <img className={styles['image-2']} src={img_2} alt="Precision forestry data" />
          </div>
          <InfoText number={2} text={t('concept.two')} />
          <img className={styles['image-2-sm']} src={img_2} alt="Precision forestry data" />
        </div>
      </div>
      <div className={styles['row']}>
        <div className={styles['column-3']}>
          <InfoText number={3} text={t('concept.three')} />
          <img className={classnames(styles['image'], styles['image-3'])} src={img_3} alt="Tool for a better forestry" />
        </div>
        <div className={styles['column-4']}>
          <img
            className={classnames(styles['image'], styles['image-4'])}
            src={img_4}
            alt="Forestry value chain role in the global economy"
          />
          <div className={styles['cell']}>
            <div className={classnames(styles['line'], styles['line-4'])} />
            <InfoText number={4} text={t('concept.four')} />
          </div>
        </div>
      </div>
    </div>
  )
}
