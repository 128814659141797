import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SECTION } from 'utils/section'
import { Section } from 'components/Section'
import { InfoBlock } from 'components/InfoBlock'
import susannaImage from './img/susanna_bw.jpg'
import styles from './Contact.module.scss'

export const Contact = () => {
  const { t } = useTranslation()
  return (
    <Section id={SECTION.CONTACT} borderTop>
      <div className={styles['container']}>
        <div className={styles['text']}>
          <InfoBlock
            title={t('contact.title')}
            content={
              <>
                <p>{t('contact.description')}</p>
                <p>
                  <Trans i18nKey="contact.email">
                    Email at <a className={styles['link']} href="mailto:info@prefor.fi">info@prefor.fi</a>
                  </Trans>
                </p>
              </>
            }
          />
        </div>
        <div className={styles['persons']}>
          <div className={styles['persons-wrapper']}>
            <div className={styles['person']}>
              <img className={styles['photo']} src={susannaImage} alt={t('contact.BC.name')} />
              <div className={styles['name']}>{t('contact.BC.name')}</div>
              <div className={styles['title']}>{t('contact.BC.title')}</div>
              <a className={styles['link']} href="mailto:susanna.kurlin@prefor.fi">susanna.kurlin@prefor.fi</a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
