import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SECTION } from 'utils/section'
import { scrollTo } from 'utils/scrollTo'
import { Button } from 'components/Button'
import styles from './Message.module.scss'

export const Message = () => {
  const { t } = useTranslation()
  return (
    <div className={styles['container']}>
      <h1 className={styles['title']}>{t('hero.title')}</h1>
      <p className={styles['text']}>{t('hero.description')}</p>
      <Button onClick={() => scrollTo(SECTION.WELCOME)}>{t('hero.learnMore')}</Button>
      <NavLink
        className={styles['link']}
        to="/"
        onClick={() => scrollTo(SECTION.COMPANY)}
      >
        {t('hero.join')}
      </NavLink>
    </div>
  )
}
