import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Logo.module.scss'
import logo from './logo.png'

export const Logo = () => (
  <NavLink to="/" className={styles['logo']}>
    <img className={styles['img']} src={logo} alt="PreFor Logo" />
  </NavLink>
)
