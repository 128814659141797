export const en = {
  page: {
    title: 'PreFor - Future for the forests',
  },

  hero: {
    title: 'We know Your trees',
    description: 'Your forest and its trees. They grow and care - but how exactly do you know what you own? PreFor knows with great accuracy.',
    learnMore: 'Learn more',
    join: 'Want to join us?',
    navigation: {
      whatWeDo: 'What we do?',
      company: 'Company',
      contacts: 'Contacts',
    },
  },

  welcome: {
    title: 'Forerunner in precision forestry',
    description: 'Forestry value chain plays a significant role in the global economy and important in the fight against climate change and ensuring high level of biodiversity. Still the practices applied in many parts of the forestry value chain are the same as they were 100 years ago. PreFor\'s precision forestry concept brings a change with its new practices. It brings together the fundamental research in individual technical disciplines and the forestry management science from the last 15 years.',
    features: {
      one: {
        title: 'Know your forests in extreme detail',
        description: 'Our precision forest inventories start from individual tree level, and we can aggregate this information to any scale you want. Forest inventories can be provided in any geospatial data format, or through our user interface.',
      },
      two: {
        title: 'Make better management decisions',
        description: 'We simulate forest growth and automatically detect the need for management actions - when and where they are needed.',
      },
      three: {
        title: 'Value your forest property',
        description: 'Need to know your property value? We can help you! We provide two different methods for forest valuation.',
      },
      four: {
        description: 'We can also provide fully customized mapping and modelling services utilizing our unique data collection capabilities and our team\'s expertise. We can provide:',
        list: {
          one: 'On-demand mapping services',
          two: 'Individual tree level growth monitoring and change detection',
          three: 'Mapping of valuable habitats and tree individuals',
        },
      },
    },
  },

  company: {
    title: 'Company',
    description: 'We are a privately owned company based in Helsinki, Finland. Our tight-knit team consists of forestry experts, drone-pilots, data scientists and software developers among others. Together we are on a mission to take forestry to the next level and make it more precise. We strongly believe that better data leads to better management of Earth\'s forests.',
  },

  contact: {
    title: 'Contact us',
    description: 'Please reach out if you are interested about our services, or if you want to join our mission!',
    email: 'You can reach us at <1>info@prefor.fi</1> or emailing us directly.',
    COO: {
      name: 'Henri Riihimäki',
      title: 'Chief Operating Officer',
    },
    BC: {
      name: 'Susanna Kurlin',
      title: 'Business controller',
    },
  },

  concept: {
    title: 'Our precision forestry concept',
    one: 'Prefor’s precision forestry uses high-density laser scanning. Our revolutionary method is based on advanced, extremely precise laser scanning carried out with drones. Our system analyses the collected data automatically and quickly.',
    two: 'Our precision forestry inventories provide data starting from the level of individual trees, and the data can be aggregated to the desired scale (grid, compartment). Precision forestry data can be supplied to the customer in any geospatial data format, and it can be viewed through our user interface.',
    three: 'We have created a tool for better forestry. We simulate forest growth and automatically identify any need for forestry measures and felling.',
    four: 'The forestry value chain plays a significant role in the global economy and is important for combatting climate change and safeguarding biodiversity. Nonetheless, surprisingly outdated approaches are still used in many parts of the chain. Our precision forestry concept changes this.',
  },
}
