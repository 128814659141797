import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { fi, en } from './translations'

const lsPreferredLngKey = 'SILVERE_PREFERRED_LNG'
const getPreferredLanguage = () => window.localStorage.getItem(lsPreferredLngKey)
const setPreferredLanguage = lng => window.localStorage.setItem(lsPreferredLngKey, lng)

const initI18N = resources => {
  i18next.use(initReactI18next).init({
    resources,
    fallbackLng: false,
    supportedLngs: resources ? Object.keys(resources) : false,
    lng: getPreferredLanguage() || 'en',
  })
  i18next.on('languageChanged', lng => {
    setPreferredLanguage(lng)
    document.title = i18next.t('page.title')
  })
}

initI18N({
  fi: { translation: fi },
  en: { translation: en },
})

document.title = i18next.t('page.title')
