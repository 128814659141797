export const fi = {
  page: {
    title: 'PreFor — Tunnemme metsäsi puut',
  },

  hero: {
    title: 'Tunnemme metsäsi puut.',
    description: 'Metsäsi ja sen puut. Ne kasvavat ja niitä hoidetaan – mutta kuinka tarkasti tiedät mitä omistat? PreFor tietää, puun ja millimetrin tarkkuudella.',
    learnMore: 'Lue lisää',
    join: 'Haluatko liittyä meihin?',
    navigation: {
      whatWeDo: 'Mitä me teemme?',
      company: 'Yhtiö',
      contacts: 'Yhteystiedot',
    },
  },

  welcome: {
    title: 'Täsmämetsätalouden edelläkävijä',
    description: 'Metsätalouden arvoketjulla on merkittävä rooli maailmantaloudessa, ja tärkeä ilmastonmuutoksen torjunnassa ja biologisen monimuotoisuuden varmistamisessa. Silti metsätalouden arvoketjun monissa osissa käytettävät käytännöt vanhentuneita. PreForin tarkkuusmetsätalouskonsepti tuo tähän muutoksen uusilla käytännöillä. Me tarjoamme markkinoiden tarkimpia metsätieto- ja metsäsuunnitelmapalveluita.',
    features: {
      one: {
        title: 'Haluatko tuntea metsäsi äärimmäisen tarkasti?',
        description: 'Täsmämetsäinventaariomme tarjoaa tietoa yksittäisen puun tasolta alkaen, ja tieto voidaan agregoida mille tahansa halutulle tasolle (hila, kuvio). Täsmämetsätieto voidaan toimittaa asiakkaalle missä tahansa paikkatietoformaatissa, ja se on nähtävissä meidän käyttöliittymän kautta.',
      },
      two: {
        title: 'Aputyökalu parempaan metsänhoitoon',
        description: 'Me simuloimme metsien kasvua ja tunnistamme automaattisesti tarpeen metsänhoitotoimenpiteille ja hakkuille, silloin kun niiden tarve on.',
      },
      three: {
        title: 'Tarkkaa tietoa tila-arvioon',
        description: 'Täsmäinventaariomme pohjalta voimme arvottaa metsäpalstat ja -tilat tarkasti. Tuemme tällä hetkellä kahta eri metsänarvotusmenetelmää.',
      },
      four: {
        description: 'Edellä mainittujen palveluiden lisäksi me teemme täysin kustomoitavissa olevia kartoitus- ja mallinnuspalveluita hyödyntäen meidän uniikkia datantuotanto ja asiantuntijakapasiteettia. Voimme toimittaa:',
        list: {
          one: '"On-demand" -kartoitukset. Tietoa silloin kuin sitä tarvitaan.',
          two: 'Yksittäisen puun tasolla tapahtuva kasvun ja muutosten monitorointi.',
          three: 'Monimuotoisuuskohteiden kartoitus.',
        },
      },
    },
  },

  company: {
    title: 'Yhtiö',
    description: 'PreFor on vuonna 2017 perustettu yksityisomisteinen yhtiö. Toimitilamme sijaitsevat Helsingissä ja Espoossa. Meidän tiimimme koostuu muun muassa metsäasiantuntijoista, droonipiloteista, analyytikoista ja devaajista. Meidän yhteinen missio on tuoda lisäarvoa metsätalouden arvoketjuun ja mahdollistaa metsävarojen parempi hoito. Me uskomme vahvasti, että parempi ja tarkempi data on avaintekijä myös metsiemme hoitoon.',
  },

  contact: {
    title: 'Ota yhteyttä',
    description: 'Ota meihin yhteyttä mikäli olet kiinnostunut meidän palveluista, tai jos haluat liittyä meidän yhteiseen missioon.',
    email: 'Tavoitat meidän osoitteesta <1>info@prefor.fi</1>, tai ottamalla yhteyttä suoraan.',
    COO: {
      name: 'Henri Riihimäki',
      title: 'Chief Operating Officer',
    },
    BC: {
      name: 'Susanna Kurlin',
      title: 'Business controller',
    },
  },

  concept: {
    title: 'Meidän täsmämetsätalouskonsepti',
    one: 'Preforin tarkkuusmetsänhoito hyödyntää tiheäpulssista lasermittausta. Mullistava menetelmämme perustuu kehittyneellä lennokilla tehtävään huipputarkkaan laserkeilaukseen. Järjestelmämme analysoi kerätyn datan automaattisesti ja nopeasti.',
    two: 'Täsmämetsäinventaariomme tarjoaa tietoa yksittäisen puun tasolta alkaen, ja tieto voidaan agregoida mille tahansa halutulle tasolle (hila, kuvio). Täsmämetsätieto voidaan toimittaa asiakkaalle missä tahansa paikkatietoformaatissa, ja se on nähtävissä käyttöliittymämme kautta.',
    three: 'Olemme luoneet aputyökalun parempaan metsänhoitoon. Me simuloimme metsien kasvua ja tunnistamme automaattisesti tarpeen metsänhoitotoimenpiteille ja hakkuille, silloin kun niiden tarve on.',
    four: 'Metsätalouden arvoketjulla on merkittävä rooli maailmantaloudessa, ja tärkeä ilmastonmuutoksen torjunnassa ja biologisen monimuotoisuuden varmistamisessa. Silti metsätalouden arvoketjun monissa osissa käytetään hämmästyttävän vanhentuneita toimintatapoja. Meidän tarkkuusmetsätalouskonsepti tuo tähän muutoksen.',
  },
}
