import React from 'react'
import classnames from 'classnames'
import styles from './InfoBlock.module.scss'

export const InfoBlock = ({ title, content, alignLeft }) => (
  <div
    className={classnames({
      [styles['container']]: true,
      [styles['alignLeft']]: alignLeft,
    })}
  >
    <h2 className={styles['title']}>{title}</h2>
    <div>{content}</div>
  </div>
)
