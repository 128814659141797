import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { InfoText } from '../../common/InfoText'
import img_1 from '../../common/img/img_1.svg'
import img_2 from '../../common/img/img_2.jpg'
import img_3 from '../../common/img/img_3.svg'
import img_4 from '../../common/img/img_4.svg'
import styles from './ConceptLarge.module.scss'

export const ConceptLarge = () => {
  const { t } = useTranslation()
  return (
    <div className={styles['container']}>
      <div className={styles['row']}>
        <div className={styles['column-1']}>
          <img className={styles['image']} src={img_1} alt="High-density laser scanning" />
          <img className={styles['image']} src={img_3} alt="Tool for a better forestry" />
        </div>
        <div className={styles['column-2']}>
          <div className={styles['cell-1']}>
            <div className={styles['line-1']} />
            <InfoText number={1} text={t('concept.one')} />
          </div>
          <div className={styles['cell-2']}>
            <div className={styles['line-2']} />
            <InfoText number={2} text={t('concept.two')} />
          </div>
          <div className={styles['cell-3']}>
            <div className={styles['line-3']} />
            <div className={styles['line-4']} />
            <div className={styles['line-5']} />
            <InfoText number={3} text={t('concept.three')} />
          </div>
          <div className={styles['cell-4']}>
            <div className={styles['line-6']} />
            <div className={styles['line-7']} />
            <InfoText number={4} text={t('concept.four')} />
          </div>
        </div>
        <div className={styles['column-3']}>
          <img className={classnames(styles['image'], styles['screenshot'])} src={img_2} alt="Precision forestry data" />
          <img className={styles['image']} src={img_4} alt="Forestry value chain role in the global economy" />
        </div>
      </div>
    </div>
  )
}
