import React from 'react'
import { Hero } from './partials/Hero'
import { Concept } from './partials/Concept'
// import { Welcome } from './partials/Welcome'
import { Company } from './partials/Company'
import { Contact } from './partials/Contact'

export const HomePage = () => (
  <>
    <Hero />
    <Concept />
    {/* <Welcome /> */}
    <Company />
    <Contact />
  </>
)
