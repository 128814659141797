import React from 'react'
import classnames from "classnames";
import styles from './Section.module.scss'

export const Section = ({ id, grey, borderTop, borderBottom, children }) => (
  <div
    id={id}
    className={classnames({
      [styles['container']]: true,
      [styles['grey']]: grey,
      [styles['border-top']]: borderTop,
      [styles['border-bottom']]: borderBottom,
    })}
  >
    <div className={styles['content']}>
      {children}
    </div>
  </div>
)
