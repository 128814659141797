import React from 'react'
import styles from './InfoText.module.scss'

export const InfoText = ({ number, text }) => (
  <div className={styles['container']}>
    <div className={styles['number-wrapper']}>
      <div className={styles['number']}>{number}</div>
    </div>

    <div className={styles['text']}>{text}</div>
  </div>
)
