import React from 'react'
import { Tree } from './partials/Tree'
import { Logo } from './partials/Logo'
import { LanguageSwitch } from './partials/LanguageSwitch'
import { Navigation } from './partials/Navigation'
import { Message } from './partials/Message'
import styles from './Hero.module.scss'

export const Hero = () => (
  <div className={styles['container']}>
    <div className={styles['content']}>
      <div className={styles['tree']}>
        <Tree />
      </div>
      <div className={styles['aside']}>
        <div className={styles['logo']}>
          <Logo />
        </div>
        <div className={styles['language']}>
          <LanguageSwitch />
        </div>
        <div className={styles['navigation']}>
          <Navigation />
        </div>
        <div className={styles['message']}>
          <Message />
        </div>
      </div>
    </div>
  </div>
)
